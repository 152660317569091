exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activitati-js": () => import("./../../../src/pages/activitati.js" /* webpackChunkName: "component---src-pages-activitati-js" */),
  "component---src-pages-articole-js": () => import("./../../../src/pages/articole.js" /* webpackChunkName: "component---src-pages-articole-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donatie-js": () => import("./../../../src/pages/donatie.js" /* webpackChunkName: "component---src-pages-donatie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-program-liturgic-js": () => import("./../../../src/pages/program-liturgic.js" /* webpackChunkName: "component---src-pages-program-liturgic-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

